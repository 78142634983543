import React, { useState, useEffect, Fragment } from 'react';
import { useStoreState } from 'easy-peasy';
import axios from 'axios';
import {
  Button
} from '@material-ui/core';
import { fetchProcessedFileStats } from '../../../components/experiment/processed/utils';
import { getPipelineOutDirOnly } from '../../../components/file/FileQCReportContainer';
const fileDownload = require('js-file-download')

// const BASEURL = 'https://5dum6c4ytb.execute-api.us-east-1.amazonaws.com/dev/submission';
const BASEURL = "https://pm23d57d8a.execute-api.us-east-1.amazonaws.com/dev/submissions";

const ProcessedFilesURLs = (props) => {
  let [processedFileURLs, setProcessedFileURLs] = useState([]);
  const { submission, Assay, uuid } = props.data;
  if(Assay === 'WGBS') {
    throw Error('Processed files for WGBS not available');
  }

  const fetchInfo = async () => {
  const getres = await axios.get(`${BASEURL}/${submission}`);
  // const SUBMISSION = getres.data.body[0];
  const SUBMISSION = getres.data;
  const outDir = getPipelineOutDirOnly(SUBMISSION, uuid);
  const res = fetchProcessedFileStats(outDir, Assay, uuid); // TODO: rethink this : right now this function reads the file listing html and parses details like file size
        res.then(resp => {
          // console.log(resp.map(d => outDir + d.name));
          setProcessedFileURLs(resp.map(d => outDir + d.name));
          props.handleFileURLs(resp.map(d => outDir + d.name));
        })
        .catch(err => {
          throw new Error(err.message);
          console.error(err);
        })
  }

  useEffect(() => {
    fetchInfo()
  }, [])

  const handleDownload = () => {
    fileDownload(processedFileURLs.join('\n'), `${Date.now()}-files.txt`)
  }
  

  if (processedFileURLs === undefined) {
    return 'Not supported';
  }

  if (processedFileURLs.length === 0) {
    return (
    <div>Loading...</div>
    )
  } else {
  return ( 
    <Fragment>
      {/* <Button onClick={handleDownload} color="primary">
            Download
      </Button> */}
      {
        processedFileURLs.map((d, i) => <div key={i}>{d}</div>) 
      }
    </Fragment>
  );
  }
}
export default ProcessedFilesURLs;