import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, IconButton, Box, Badge, Card } from '@material-ui/core';

import CountUp from 'react-countup';
import Chart from 'react-apexcharts';
import Circle from 'react-circle';


export default function LivePreviewExample(props) {
  /**
alignment_program: "bwa"
alignment_program_parameters: "bwa mem"
alignment_program_version: "0.7.16a"
post_alignment_program: "methylQA"
post_alignment_program_parameters: "methylQA atac"
post_alignment_program_version: "0.2.1"
   */
  const non_redundant_mapped_reads = props.data.mapping_stats['non-redundant_mapped_reads'];
  const { total_reads, mapped_reads, uniquely_mapped_reads, useful_single_ends, alignment_program, alignment_program_version, post_alignment_program, post_alignment_program_version  } = props.data.mapping_stats;
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <Card className="card-box mb-4 px-4 pt-4 text-center">
            <Box className="card-tr-actions">
              <IconButton color="secondary" className="font-size-xl">
                <FontAwesomeIcon
                  icon={['fas', 'ellipsis-h']}
                  className="font-size-lg"
                />
              </IconButton>
            </Box>
            <div className="card-header-alt">
              <div className="font-size-lg mb-0 text-black">
                Total Reads
              </div>
              {/* <p className="text-black-50">Expenses statistics to date</p> */}
            </div>
            {/* <div className="divider my-4" /> */}
            <h3 className="mb-0 display-3 mt-1 font-weight-bold">
              
              <span className="pr-1">
              {numberWithCommas(total_reads)}
              </span>
            </h3>
            <div className="divider my-4" />
            <small className="opacity-6 py-1">Alignment statistics</small>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6} className="d-flex justify-content-center">
                <div className="divider-v divider-v-lg" />
                <div>
                  <div className="d-flex align-items-center justify-content-center text-uppercase text-black-50 pb-3">
                    <span className="badge-circle mr-2 badge badge-success">
                      total
                    </span>
                    <span>Mapped Reads</span>
                  </div>
                  <Circle
                    animate={true} // Boolean: Animated/Static progress
                    animationDuration="3s" //String: Length of animation
                    responsive={false} // Boolean: Make SVG adapt to parent size
                    size={160} // Number: Defines the size of the circle.
                    lineWidth={22} // Number: Defines the thickness of the circle's stroke.
                    progress={calcPercentage(mapped_reads, total_reads)} // Number: Update to change the progress and percentage.
                    progressColor="#1bc943" // String: Color of "progress" portion of circle.
                    bgColor="#e8e9ef" // String: Color of "empty" portion of circle.
                    textColor="#3b3e66" // String: Color of percentage text color.percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                    roundedStroke={true}
                    textStyle={{
                      fontSize: '60px',
                      fontWeight: 'bold'
                    }} // Boolean: Rounded/Flat line ends
                    showPercentage={true} // Boolean: Show/hide percentage.
                    showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} className="d-flex justify-content-center">
                <div>
                  <div className="d-flex align-items-center justify-content-center text-uppercase text-black-50 pb-3">
                    <span className="badge-circle mr-2 badge badge-primary">
                      available
                    </span>
                    <span>Uniquely Mapped</span>
                  </div>
                  <Circle
                    animate={true} // Boolean: Animated/Static progress
                    animationDuration="3s" //String: Length of animation
                    responsive={false} // Boolean: Make SVG adapt to parent size
                    size={160} // Number: Defines the size of the circle.
                    lineWidth={22} // Number: Defines the thickness of the circle's stroke.
                    progress={calcPercentage(uniquely_mapped_reads, total_reads)} // Number: Update to change the progress and percentage.
                    progressColor="#5383FF" // String: Color of "progress" portion of circle.
                    bgColor="#e8e9ef" // String: Color of "empty" portion of circle.
                    textColor="#3b3e66" // String: Color of percentage text color.percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                    roundedStroke={true}
                    textStyle={{
                      fontSize: '60px',
                      fontWeight: 'bold'
                    }} // Boolean: Rounded/Flat line ends
                    showPercentage={true} // Boolean: Show/hide percentage.
                    showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                  />
                </div>
              </Grid>
            </Grid>
            <div className="pt-4" />
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6} className="d-flex justify-content-center">
                {/* <div className="divider-v divider-v-lg" /> */}
                <div>
                  <div className="d-flex align-items-center justify-content-center text-uppercase text-black-50 pb-3">
                    <span className="badge-circle mr-2 badge badge-warning">
                      total
                    </span>
                    <span>Non Redundant</span>
                  </div>
                  <Circle
                    animate={true} // Boolean: Animated/Static progress
                    animationDuration="3s" //String: Length of animation
                    responsive={false} // Boolean: Make SVG adapt to parent size
                    size={160} // Number: Defines the size of the circle.
                    lineWidth={22} // Number: Defines the thickness of the circle's stroke.
                    progress={calcPercentage(non_redundant_mapped_reads, total_reads)} // Number: Update to change the progress and percentage.
                    progressColor="#F4772E" // String: Color of "progress" portion of circle.
                    bgColor="#e8e9ef" // String: Color of "empty" portion of circle.
                    textColor="#3b3e66" // String: Color of percentage text color.percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                    roundedStroke={true}
                    textStyle={{
                      fontSize: '60px',
                      fontWeight: 'bold'
                    }} // Boolean: Rounded/Flat line ends
                    showPercentage={true} // Boolean: Show/hide percentage.
                    showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} className="d-flex justify-content-center align-items-center ">
                <div className=" py-4" />
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <span className="opacity-6 pb-2">Alignment program</span>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="font-weight-bold font-size-lg">
                        {alignment_program}
                      </span>
                      <Badge color="secondary" className="ml-2 text-warning">
                        {alignment_program_version}
                      </Badge>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <span className="opacity-6 pb-2">Post alignment</span>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="font-weight-bold font-size-lg">
                        {post_alignment_program}
                      </span>
                      <Badge color="secondary" className="text-success ml-2">
                        {post_alignment_program_version}
                      </Badge>
                    </div>
                  </Grid>
              </Grid>
            </Grid>
            </Grid>
            <div className="divider my-4" />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <span className="opacity-6 pt-2">Useful Single Reads</span>
                <div className="d-flex align-items-center justify-content-center">
                  <span className="font-weight-bold font-size-lg pt-1">
                    {numberWithCommas(useful_single_ends)}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12}>
                <small className="opacity-6 pr-1">Good : </small>
                <Badge color="secondary" className="text-success ml-2">
                  40,000,000
                </Badge>
                <small className="opacity-6 ml-2 px-1"> Acceptable : </small>
                <Badge color="secondary" className="text-danger ml-2">
                  25,000,000
                </Badge>
              </Grid>
            </Grid>
            <div className=" my-4" />
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}


function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function calcPercentage(x, y) {
  return Math.round(x / y * 100)
}