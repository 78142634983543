import React from 'react';
import LineChart from './LineChart';
import * as d3 from 'd3';
import {Grid, Badge, Card} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';

//label
// x-data
// y-data
// d3.linearScale 
// xTicks
// yValues
// pass it to LineChart

const useStyles = makeStyles((theme) => ({
    fab: {
      margin: theme.spacing(2),
    },
    absolute: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
  }));
const LineChartContainer = (props) => {
    const classes = useStyles();

        const { xData, yData, dataLabel } = props;

        return (
            <Grid container spacing={4}>
                <Grid item xs={12} lg={12}>
                    <Card className="card-box mb-4 px-4 pt-4 text-center">
                        <div className="card-header-alt">
                            <div className="font-size-lg mb-0 text-black">
                                {dataLabel}
                            </div>
                            {/* <p className="text-black-50">{props.subtitle || ""}</p> */}
                            {props.subtitle &&
                                <Tooltip title={props.subtitle} aria-label="add">
                                    <HelpIcon />
                                </Tooltip>
                            }
                        </div>
                        <LineChart data={formatData(xData, yData)} {...props}/>
                        {/* <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <span className="opacity-6 pt-2">Non-redundantly Uniquely Mapped Reads</span>
                                <div className="d-flex align-items-center justify-content-center"></div>
                            </Grid>
                            <Grid item xs={12}>
                                <small className="opacity-6 pr-1">chrX :
                                </small>
                                <Badge color="secondary" className="text-success ml-2">
                                    {chrX}
                                    %
                                </Badge>
                                <small className="opacity-6 ml-2">
                                    chrY :
                                </small>
                                <Badge color="secondary" className="text-primary ml-2">
                                    {chrY}
                                    %
                                </Badge>
                            </Grid>
                            <Grid item xs={12}>
                                <span className="opacity-6">Uniquely Mapped Reads</span>
                                <div className="d-flex align-items-center justify-content-center"></div>
                            </Grid>
                            <Grid item xs={12}>
                                <small className="opacity-6 pr-1">chrM :
                                </small>
                                <Badge color="secondary" className="text-warning ml-2">
                                    {chrM}
                                    %
                                </Badge>
                            </Grid>
                        </Grid> */}
                        <div className=" my-4"/>
                    </Card>
                </Grid>
            </Grid>
        );
}

export default LineChartContainer;

function formatData(xData, yData) {

  const x = d3.scaleLinear().domain(xData).range(yData);
  const xTicks =d3.scaleLinear().domain(d3.extent(xData)).ticks(10);
  const yValues = xTicks.map(d => Number.parseFloat(x(d)).toFixed(2));
  return { x: xTicks, y: yValues };

}