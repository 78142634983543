import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import Results from './filter/Results'
import { useStoreState } from 'easy-peasy';
import EnhancedTable from './EnhancedTable';
import { makeStyles } from '@material-ui/core/styles';
import UserList from '../views/UserList';
import DownloadView from '../views/DownloadView';
import DatahubView from '../views/DatahubView';
import Dashboard from '../views/Dashboard';
import Overview from '../views/Overview';
import CartContent from '../views/Cart/CartContent';
import ExperimentList from '../views/ExperimentList';

const styles = theme => ({
  paper: {
    // maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));


function Content(props) {
  const { classes } = props;
  const activeTab = useStoreState(state => state.tabs.active);
  const classesChild = useStyles();

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            {/* <Grid item>
              <SearchIcon className={classes.block} color="inherit" />
            </Grid> */}
            <Grid item xs>
              {activeTab === 0 && 
              <TextField
                fullWidth
                // placeholder="Arranged by Exposure -> Assay on Rows, Tissue -> Age on Columns"
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />}
            </Grid>
            <Grid item>
              {/* <Button variant="contained" color="primary" className={classes.addUser}>
                Add user
              </Button> */}
              {/* <Tooltip title="Reload">
                <IconButton>
                  <RefreshIcon className={classes.block} color="inherit" />
                </IconButton>
              </Tooltip> */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {/* <Typography color="textSecondary" align="center">
          No users for this project yet
        </Typography> */}
          <Grid>
          {/* <Typography color="textSecondary" align="center"> */}
            {/* <ViewHeader/> */}
        {/* </Typography> */}
          </Grid>
          <Grid item align="center">
            {activeTab === 0 && 
            <div>
            <Results 
            // viewExperiments={this.state.viewExperiments} 
            // handleClose={this.setMode}
            />
            </div>
            }
            {activeTab === 10 && 
              // <ExperimentItems classes={classesChild}/>
              <ExperimentList>
                <UserList/>
              </ExperimentList>
            }
            {activeTab === 2 && 
              <DownloadView/>
              // <Overview/>
            }
            {activeTab === 3 && 
              <DatahubView/>
              // <Dashboard/>
            }
            {activeTab === 4 && 
              <CartContent/>
              // <Dashboard/>
            }
          </Grid>
      </div>
    </Paper>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);

const ViewHeader = () => {
  const tab = useStoreState(state => state.tabs.active);
  return ( 
  <Typography color="textSecondary" align="center">
    {tab}
  </Typography> );
}
 


const ExperimentItems = (props) => {
  const { classes } = props;
  const added = useStoreState(state => state.data.added);

  if (added.length === 0) {
    return <p>Nothing added</p>;
  }
  console.log(added);
  return ( 
    
    <div>
      {/* <EnhancedTable data={added}/> */}
          {/* {added.map((d, index) => <li>{d._id}</li>)} */}
          <List className={classes.root}>
            {added.map((d, index) => 
            <React.Fragment>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={d._id}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {d.Tissue}
                      </Typography>
                      {` -  ${d.Assay}`}
                    </React.Fragment>
                  }
                />
                <ListItemText
                  primary={d.Exposure}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        
                      </Typography>
                      {`   ${d.Lab}`}
                    </React.Fragment>
                  }
                />
                <ListItemText
                  primary={d.Age}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        QC status
                      </Typography>
                      {` -  ${d.status}`}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
          </React.Fragment>
      )}
          </List>
    </div>
  );
}

