import React, {Component} from 'react';
import {Button, Label, Form} from 'semantic-ui-react'
import axios from 'axios'
// const POST_DATAHUB_URL = "https://5dum6c4ytb.execute-api.us-east-1.amazonaws.com/dev/datahub"
// const POST_DATAHUB_URL = "http://localhost:3000/dev/datahubs"
const POST_DATAHUB_URL = "https://pm23d57d8a.execute-api.us-east-1.amazonaws.com/dev/datahubs"
const WEB_DIR = 'https://target.wustl.edu/files';

const uuid = require('uuid')
/**
 * const DatahubSchema = new Schema({
   _id: String,
   files: Array,
   hub: Object,
   compositegraphdata: Object,
   comments: String,
   registered: Date,
   username: String
});
 */
// const DATAHUB_MONGO_API = "https://5dum6c4ytb.execute-api.us-east-1.amazonaws.com/dev/datahub"
const DATAHUB_MONGO_API = "https://pm23d57d8a.execute-api.us-east-1.amazonaws.com/dev/datahubs";
const BROWSER_URL = "https://epigenomegateway.wustl.edu/browser/?genome=mm10&hub="
// const BASEURL = 'https://5dum6c4ytb.execute-api.us-east-1.amazonaws.com/dev/submission';
const BASEURL = "https://pm23d57d8a.execute-api.us-east-1.amazonaws.com/dev/submissions";


class NewBrowserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datahubID: null
        }
    }

    async componentDidMount() {
        
        let generatedDatahub = await createDatahub(this.props.data)
        const _id = uuid.v4()
        const toPost = {
            _id: _id,
            files: this.props.data || [],
            hub: { content: generatedDatahub },
            comments: 'TEST',
            compositegraphdata: {},
            registered: Date(),
            username: 'dpuru'
        }

        axios
            .post(POST_DATAHUB_URL, toPost)
            .then(res => {
                let resBody = res.data;
                if (resBody.hasOwnProperty('_id')) {
                    this.setState({datahubID: resBody._id})
                }
            })
            .catch(err => console.log(err))
    }

    render() { 
        const link = `${BROWSER_URL}${DATAHUB_MONGO_API}/${this.state.datahubID}`
        return (
            <div className="test">
                {this.state.datahubID
                    ? 
                    <div className="">
                        <div className="text-center font-sans">Datahub</div>
                        <div className="flex justify-center m-8 p-4 bg-grey-darker text-grey-darker font-mono">{POST_DATAHUB_URL + '/' + this.state.datahubID}</div>
                        <div className="flex justify-center bg-grey-darker text-grey-darker">Note: Only 5 files are displayed by default on the browser</div>
                        <br/>
                        <div className="flex justify-center m-8 pb-4 bg-grey-darker text-grey-darker">Navigate: <strong>Tracks -> Tracks Facet Table -> Custom tracks facet table</strong>, to select more files</div>
                        <div className='flex justify-center'>
                            <a href={link} target='_blank'>
                                    <Label>mm10</Label>
                                    <Button
                                        className="m-8"
                                        color='pink'
                                        icon='external'
                                        content='WashU Epigenome Browser'/>
                                </a>
                            </div>
                        </div>
                        
                    
                    : 
                    <div className="p-8 m-8 bg-orange-dark text-grey-lightest box-shadow">
                        'Generating Datahub ..Please wait..'
                    </div>
                }
                
            </div>
        );
    }
}

export default NewBrowserView;


const createDatahub = (list, submissionObj) => {
    const response = list.map(async (item, index) => {
        
        const {
            Assay,
            Dose,
            Exposure,
            Lab,
            Sex,
            Tissue,
            uuid,
            submission
        } = item;
        const getres = await axios.get(`${BASEURL}/${submission}`);
        // const submissionObj = getres.data.body[0];
        const submissionObj = getres.data;
        let metadata = {};
        if (index < 5) {
            metadata.showOnHubLoad = true;
        } else {
            metadata.showOnHubLoad = false;
        }
        metadata.type = getDatahubType(item);
        metadata.url = getPipelineOutDir(item, submissionObj);
        metadata.name = `${Tissue}  ${Assay}  ${Sex}  ${Exposure}  `;
        metadata.metadata = {
            Assay,
            Dose,
            Exposure,
            Lab,
            Sex,
            Tissue
        }

        return  metadata;
    })

    return Promise.all(response);
}
function getDatahubType(row) {
    const { Assay } = row;
    let datahubType = null;
    if (Assay === 'ATAC-seq') {
        datahubType = 'bigWig'
    } else if (Assay === 'RNA-seq') {
        datahubType = 'bedGraph'
    } else if (Assay === 'RRBS-Seq') {
        datahubType = 'bedGraph'
    } else if (Assay === 'WGBS') {
        datahubType = 'bedGraph'
    } else {
        alert(Assay + " is not supported yet");
    }

    
    return datahubType;
}


const FormMetadata = () => (
    <Form>
        <h3>Select Metadata to include in datahub</h3>
        <Form.Group grouped>
            <label>Select</label>
            <Form.Field label='Tissue' control='input' type='checkbox'/>
            <Form.Field label='assay' control='input' type='checkbox'/>
        </Form.Group>
        <Button type='submit'>Submit</Button>
    </Form>
)

function getPipelineOutDir(obj, submissionObj) {
    const { serverPath } = submissionObj;
    const { Assay, submission, uuid } = obj;
    const assay = Assay.split(' ')[0]; // Just extract ATAC-seq or RNA-seq
    let outDir = `${WEB_DIR}/${assay}/${submission}/${uuid}`;

    if (assay === 'RRBS-Seq') {
        outDir = `${WEB_DIR}/RRBS-seq/${submission}/${uuid}`;
    }

    if (assay === 'WGBS') {
        outDir = `${WEB_DIR}/WGBS/${submission}/${uuid}`;
    }
    if (assay === 'ATAC-seq') {
        return `${outDir}/${uuid}.bigWig`;
    } else if (assay === 'RNA-seq') {
        if (serverPath !== undefined) {
            outDir = `${WEB_DIR}${serverPath}/${uuid}`;
        }
        return `${outDir}/${uuid}.sbg.gz`;
    } else if (assay === 'RRBS-Seq') {
        return `${outDir}/${uuid}.Q10.methylCall.gz`;
    } else if (assay === 'WGBS') {
        return `${outDir}/${uuid}.deduplicated.Q10.bismark.bg.gz`;
    } else {
        alert(assay + "is not supported yet");
    }
}