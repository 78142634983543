import React, {Fragment} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Grid, Card, CardContent, Divider} from '@material-ui/core';

import CountUp from 'react-countup';

export default function DetailsCard(props) {
  const {
    mouse_animal_weight_sac,
    mouse_strain,
    mouse_life_stage_collection,
    mouse_fasted,
    mouse_age_precise,
    mouse_internal_id,
    mouse_liver_tumors,
    mouse_perfusion
  } = props.result[0];
  console.log(props.result[0]);
  return (
    <Fragment>
      <Card className="card-box mb-4">
        <div className="card-header">
          <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
            Mouse details
          </h4>
        </div>
        <CardContent className="px-5 pt-5">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'chart-bar']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Life stage</b>
            </div>
            <div className="font-weight-bold text-success font-size-lg">
              {mouse_life_stage_collection}
            </div>
          </div>
          <Divider className="my-3"/>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'address-card']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Precise age</b>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              {mouse_age_precise}
            </div>
          </div>
          <Divider className="my-3"/>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'object-group']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Fasted?</b>
            </div>
            <div className="font-weight-bold text-first font-size-lg">
              {mouse_fasted}
            </div>
          </div>
          <Divider className="my-3"/>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'object-group']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Strain</b>
            </div>
            <div className="font-weight-lg text-first font-size-md">
              {mouse_strain}
            </div>
          </div>
          <Divider className="my-3"/>

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'comment-dots']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Internal ID</b>
            </div>
            <div className="font-weight-lg text-first font-size-md">
              {mouse_internal_id}
            </div>

          </div>
          <Divider className="my-3"/>

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'comment-dots']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Weight at sac</b>
            </div>
            <div className="font-weight-lg text-first font-size-sm">
              {(mouse_animal_weight_sac)
                ? mouse_animal_weight_sac
                : 'N/A'}
            </div>

          </div>
          <Divider className="my-3"/>

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'comment-dots']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Liver tumors</b>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              {/* {(mouse_liver_tumors === 'FALSE') ? "No": "Yes"} */}
              {mouse_liver_tumors}
            </div>

          </div>
          <Divider className="my-3"/>

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'comment-dots']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Perfusion</b>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              {mouse_perfusion}
            </div>

          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
}