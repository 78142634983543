import React, { Fragment } from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,
  IconButton,
  Box,
  LinearProgress,
  Card,
  Button
} from '@material-ui/core';

const GSMIds = require('../../../json/gsm_uuid.json');

export default function QCInformation(props) {
  const {
    file_name,
    pipe_version,
    genome,
    read_type,
    Docker_image_id,
    date,
    bash_script_MD5
} = getInformation(props.data);
  console.log(props.accession)
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <Card className="card-box mb-4 p-4">
            <Box className="card-tr-actions">
              <IconButton color="secondary" className="font-size-xl">
                <FontAwesomeIcon
                  icon={['fas', 'ellipsis-h']}
                  className="font-size-lg"
                />
              </IconButton>
            </Box>
            <div className="d-flex align-items-center mb-3">
              <div className="w-100">
                <div className="font-weight-bold font-size-lg">
                  {/* Heading */}
                  {props.assay}
                </div>
                <span className="text-black-50 d-block">
                  {/* subtitle. */}
                  
                  <a href={`https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${GSMIds[props.accession]}`} target="_blank" rel="noopener noreferrer">
                    {GSMIds[props.accession]} 
                  </a>
                </span>
              </div>
            </div>
            <p className="text-black-50 font-size-md mb-0">
              {/* description. */}
            </p>
            <div className="my-4 font-size-sm p-3 bg-secondary rounded-sm">
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">Report Id:</span>
                <span className="text-black-50">{file_name.split('.')[0]}</span>
              </div>
              <div className="d-flex justify-content-between py-2">
                <span className="font-weight-bold">Genome:</span>
                <span className="text-black-50">{genome}</span>
              </div>
              <div className="d-flex justify-content-between py-2">
                <span className="font-weight-bold">Read type:</span>
                <span className="text-black-50">{read_type}</span>
              </div>
              <div className="d-flex justify-content-between py-2">
                <span className="font-weight-bold">Pipeline version:</span>
                <span className="text-black-50">{pipe_version}</span>
              </div>
              <div className="d-flex justify-content-between py-2">
                <span className="font-weight-bold">Docker image id:</span>
                <span className="text-black-50">{Docker_image_id}</span>
              </div>
              <div className="d-flex justify-content-between py-2">
                <span className="font-weight-bold">Bash script MD5:</span>
                <span className="text-black-50">{bash_script_MD5}</span>
              </div>
              <div className="d-flex justify-content-between py-2">
                <span className="font-weight-bold">Processed on:</span>
                <span className="text-black-50">{date}</span>
              </div>
            </div>
            <a href={`https://github.com/Zhang-lab/${props.assay}_QC_analysis/blob/master/README.md`} target='_blank'>
            <Button
              variant="outlined"
              color="primary"
              className="text-uppercase w-100 font-size-xs">
              <small className="font-weight-bold">View Pipeline documentation</small>
            </Button>
            </a>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}


function getInformation(data) {
  const {data_information} = data;
  data_information.date = sanitizeDate(data_information.running_time);

  return data_information;
}

function sanitizeDate(str) {
  const dateStr = str
      .split('_')
      .join(" ");

  const dateStrDate = new Date(dateStr)

  const date = moment(dateStrDate).format('LLL');
  return date;
}