import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
// import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { fade, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import SearchBar from './filter/SearchBar';
import Badge from './Badge';
import { useStoreState, useStoreActions } from 'easy-peasy';
const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  // menuButton: {
  //   marginLeft: -theme.spacing(1),
  // },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  root: {
    flexGrow: 1
},
menuButton: {
    marginRight: theme.spacing(2)
},
title: {
    flexGrow: 1,
    display: 'none',
    [
        theme
            .breakpoints
            .up('sm')
    ]: {
        display: 'block'
    }
},
search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [
        theme
            .breakpoints
            .up('sm')
    ]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
    }
},
searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
},
inputRoot: {
    color: 'inherit'
},
inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme
        .transitions
        .create('width'),
    width: '100%',
    [
        theme
            .breakpoints
            .up('sm')
    ]: {
        width: 200,
        '&:focus': {
            width: 400
        }
    }
}
});

function Header(props) {
  const { classes, onDrawerToggle } = props;
  const active = useStoreState(state => state.tabs.active);
  const setActive = useStoreActions(actions => actions.tabs.setActive);

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />

            <Grid>
            <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon/>
                        </div>
                        {/* <InputBase
                            placeholder="Search…"
                            classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput
                        }}
                            inputProps={{
                            'aria-label': 'search'
                        }}> */}
                          <SearchBar/>
                        {/* </InputBase> */}
                    </div>
            </Grid>
            {/* <Grid item>
              <Link className={classes.link} href="#" variant="body2">
                Go to docs
              </Link>
            </Grid> */}
            {/* <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton color="inherit" className={classes.iconButtonAvatar}>
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
              </IconButton>
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                DATA PORTAL
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button className={classes.button} variant="outlined" color="inherit" size="small">
                Web setup
              </Button>
            </Grid> */}
            {/* <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
            <Grid item>
              <Badge/>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={active}
      >
        <Tabs value={active} textColor="inherit">
          <Tab textColor="inherit" label="Experiment Matrix" onClick={() => setActive(0)}/>
          <Tab textColor="inherit" label="Experiment List" onClick={() => setActive(1)} /> 
          <Tab textColor="inherit" label="Download" onClick={() => setActive(2)} />
          <Tab textColor="inherit" label="Visualize" onClick={() => setActive(3)} />
        </Tabs>
      </AppBar> */}
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);