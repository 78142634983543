import React from 'react';
import LineChartContainer from './components/LineChartContainer';
import SANITIZED_LABELS from '../sections/sanitized_labels.json';

const PeaksLengthDistribution = (props) => {
    if (Object.keys(props.data).length === 0) {
        return <p>Loading...</p>
    } else {
        const { data } = props;
        const SATURATION = data['saturation'];
        const PEAKS_NUMBER = SATURATION['peaks_number'];
        const SEQUENCING_DEPTH = SATURATION['sequence_depth'];
        const dataLabel = SANITIZED_LABELS['peaks_number'];
        return (
            <LineChartContainer xData={SEQUENCING_DEPTH} yData={PEAKS_NUMBER} dataLabel={dataLabel} 
            xTitle="Sequence Depth" yTitle="Peaks" subtitle="Result of the saturation analysis showing the number of peaks that were detected as a function of the percentage of sequencing depth"/>
        );
    }
}

export default PeaksLengthDistribution;