import React, {Fragment} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Grid, Card, CardContent, Divider} from '@material-ui/core';

import CountUp from 'react-countup';

export default function DetailsCard(props) {
  const {treatment_exposure_category, treatment_exposure_life_stage, treatment_exposure_paradigm, treatment_exposure_age_last, treatment_exposure_specific } = props.result[0];
  return (
    <Fragment>
      <Card className="card-box mb-4">
        <div className="card-header">
          <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
            Treatment details
          </h4>
        </div>
        <CardContent className="px-5 pt-5">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'comment-dots']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Exposure Category</b>
            </div>
            <div className="font-weight-bold text-danger font-size-lg">
              {treatment_exposure_category}
            </div>

          </div>
          <Divider className="my-3"/>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'chart-bar']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Exposure stage</b>
            </div>
            <div className="font-weight-bold text-success font-size-lg">
              {treatment_exposure_life_stage}
            </div>
          </div>
          <Divider className="my-3"/>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'address-card']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Exposed age last</b>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              {treatment_exposure_age_last}
            </div>
          </div>
          <Divider className="my-3"/>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'object-group']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Exposure paradigm</b>
            </div>
            <div className="ml-3 pl-6 font-weight-thin text-first font-size-sm">
              {treatment_exposure_paradigm}
            </div>
          </div>
          <Divider className="my-3"/>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={['far', 'object-group']}
                className="font-size-lg d-block mr-3 text-dark opacity-5"/>
              <b className="text-dark">Exposure specific name</b>
            </div>
            <div className="pl-3 font-weight-thin text-first font-size-lg">
              {treatment_exposure_specific}
            </div>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
}