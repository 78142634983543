import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

const CellComponent = (props) => {
  const selected = useStoreState(state => state.data.selected);

  if (!props.data) {
    return <td className='cursor-not-allowed'></td>;
  }
  if (selected.length > 0) {
    const selectedPresentHere = selected.map(d => d._id).filter((val) => props.data.indexOf(val) !== -1); 
    if (selectedPresentHere.length > 0) { 
    return ( 
      <td onClick={() => props.handleClick()} className='cursor-pointer bg-blue-100'>
        <span className='text-blue-600'><strong>{selectedPresentHere.length}</strong> / {props.data.length}</span>
      </td>
     );
    }
  }
  return ( 
    <td onClick={() => props.handleClick()} className='cursor-pointer'>
      {props.data.length}
    </td>
   );
}

export default CellComponent;