import React from 'react';
import Datahub from './components/Datahub';

const DatahubView = () => {
  return (
    <React.Fragment>
    {/* <p>Datahub is generated dynamically using all the selected experiments in
      the Cart</p> */}
      <Datahub/>
    </React.Fragment>
  );
}

export default DatahubView;