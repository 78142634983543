import React from 'react';
import LineChartContainer from './components/LineChartContainer';
import SANITIZED_LABELS from '../sections/sanitized_labels.json';

const PeaksLengthDistribution = (props) => {
    if (Object.keys(props.data).length === 0) {
        return <p>Loading...</p>
    } else {
        const { data } = props;
        const SATURATION = data['saturation'];
        const PEAKS_NUMBER = SATURATION['percentage_of_peak_region_recaptured'];
        const SEQUENCING_DEPTH = SATURATION['sequence_depth'];
        const dataLabel = SANITIZED_LABELS['percentage_of_peak_region_recaptured'];
        return (
            <LineChartContainer xData={SEQUENCING_DEPTH} yData={PEAKS_NUMBER} dataLabel={dataLabel} 
            xTitle="Sequence Depth" yTitle="Peak regions" subtitle="Result of the saturation analysis showing the percentage of peak regions that were detected as a function of the percentage of sequencing depth (the size of the overlapping peak regions between the original and the sub-sampled data divided by the size of all the peaks in the original data)"/>
        );
    }
}

export default PeaksLengthDistribution;