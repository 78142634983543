import React, {Component} from 'react';
import { Button, Image, Modal, Icon } from 'semantic-ui-react';
import BrowserView from './BrowserView';
import { useStoreState } from 'easy-peasy';
const inlineStyle = {
    modal: {
        marginTop: '10px !important',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
};
const Datahub = () => {
  const selected = useStoreState(state => state.data.selected);

  return ( 
    <Modal
  trigger={<Button className="m-8" basic color='pink' icon='external' content='View in Browser'/>}
  style={inlineStyle.modal} size='large'>
  <Modal.Header>Open visualization browser</Modal.Header>
  <Modal.Content>
      <Modal.Description>
          <div className='flex justify-center text-lg m-8'>
          
              <a target='_blank' href="https://www.youtube.com/watch?v=MPREBr0klQ4">How to add tracks on WashU Browser?</a>
          </div>
          <BrowserView data={selected}/>
      </Modal.Description>
  </Modal.Content>
</Modal>
   );
}
 
export default Datahub;
