import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useStoreActions } from 'easy-peasy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,
  Input,
  InputLabel,
  InputAdornment,
  FormControlLabel,
  // Avatar,
  IconButton,
  Box,
  Typography,
  Dialog,
  Checkbox,
  Tabs,
  Tab,
  LinearProgress,
  Badge,
  Card,
  CardContent,
  Button,
  Tooltip,
  FormControl
} from '@material-ui/core';

// import AvatarGroup from '@material-ui/lab/AvatarGroup';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import hero1 from '../../assets/images/hero-bg/hero-1.jpg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const LivePreviewExample = (props) => {
  const setActive = useStoreActions(actions => actions.tabs.setActive);
  const [modal1, setModal1] = useState(true);

  const toggle1 = () => {
    setModal1(!modal1);
    setActive(0);
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [checked1, setChecked1] = React.useState(true);

  const handleChange1 = event => {
    setChecked1(event.target.checked);
  };
  return (
    <Fragment>
      <Dialog scroll="body" maxWidth="lg" open={modal1} onClose={toggle1}>
        <Grid container spacing={0}>
          {props.children}
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default LivePreviewExample;
