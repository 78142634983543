import React from 'react';
import {DataSearch} from "@appbaseio/reactivesearch";
import {fade, makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            display: 'block'
        }
    },
    searchBar: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginLeft: 0,
        width: '100%',
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme
            .transitions
            .create('width'),
        color: "#75590c",
        width: '100%',
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            width: 420,
            '&:focus': {
                width: 400
            }
        }
    }
}));
const SearchBar = () => {
    const classes = useStyles();

    return (
        <div className={classes.inputInput}>
    <DataSearch
        componentId="Search"
        dataField={["mouse", "accession", "status", "biosample", "assay", "Exposure", "Assay", "Tissue", "Age", "uuid", "Lab", "submission", "experiment", "experiment_set"]}
        categoryField="title"
        className={classes.searchBar}
        queryFormat="and" //only results matching both “bat” and “man” will be returned
        placeholder="Search..."
        iconPosition="left"
        autosuggest={true}
        URLParams={true}
        filterLabel="search"/>
        </div>
    );
}

export default SearchBar;