import React from 'react';
import LineChartContainer from './components/LineChartContainer';
import SANITIZED_LABELS from '../sections/sanitized_labels.json';

const PeaksLengthDistribution = (props) => {
    if (Object.keys(props.data).length === 0) {
        return <p>Loading...</p>
    } else {
        const { data } = props;
        const PEAK_LENGTH_DISTRIBUTION_DATA = data['peak_length_distribution'];
        const PEAK_LENGTH = PEAK_LENGTH_DISTRIBUTION_DATA['peak_length'];
        const DENSITY = PEAK_LENGTH_DISTRIBUTION_DATA['density'];
        const dataLabel = SANITIZED_LABELS['peak_length_distribution'];
        return (
            <LineChartContainer xData={PEAK_LENGTH} yData={DENSITY} dataLabel={dataLabel} subtitle="Distribution of ATAC-seq peak width"/>
        );
    }
}

export default PeaksLengthDistribution;