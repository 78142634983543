import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import HomeIcon from '@material-ui/icons/Home'; import PeopleIcon from
// '@material-ui/icons/People'; import DnsRoundedIcon from
// '@material-ui/icons/DnsRounded'; import PermMediaOutlinedIcon from
// '@material-ui/icons/PhotoSizeSelectActual'; import PublicIcon from
// '@material-ui/icons/Public'; import SettingsEthernetIcon from
// '@material-ui/icons/SettingsEthernet'; import SettingsInputComponentIcon from
// '@material-ui/icons/SettingsInputComponent'; import TimerIcon from
// '@material-ui/icons/Timer'; import SettingsIcon from
// '@material-ui/icons/Settings'; import PhonelinkSetupIcon from
// '@material-ui/icons/PhonelinkSetup';
import Tissues from './filter/Tissues';
import Assays from './filter/Assays';
import Exposures from './filter/Exposures';
// import ExposuresCustom from './filter/ExposuresCustom';
import Labs from './filter/Labs';
import Sex from './filter/Sex';
import Age from './filter/Age';

// const categories = [   {     id: 'Develop',     children: [       { id:
// 'Authentication', icon: <PeopleIcon />, active: true },       { id:
// 'Database', icon: <DnsRoundedIcon /> },       { id: 'Storage', icon:
// <PermMediaOutlinedIcon /> },       { id: 'Hosting', icon: <PublicIcon /> },
//     { id: 'Functions', icon: <SettingsEthernetIcon /> },       { id: 'ML
// Kit', icon: <SettingsInputComponentIcon /> },     ],   },   {     id:
// 'Quality',     children: [       { id: 'Analytics', icon: <SettingsIcon /> },
//       { id: 'Performance', icon: <TimerIcon /> },       { id: 'Test Lab',
// icon: <PhonelinkSetupIcon /> },     ],   }, ];

const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  categoryHeaderPrimary: {
    // color: theme.palette.common.white,
    color: '#404854'
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    // color: 'rgba(255, 255, 255, 0.7)',
    color: '#404854',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)'
    }
  },
  itemCategory: {
    // backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  firebase: {
    fontSize: 24,
    // color: theme.palette.common.white,
    color: ''
  },
  itemActiveItem: {
    color: '#4fc3f7'
  },
  itemPrimary: {
    fontSize: 'inherit'
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2)
  },
  divider: {
    // marginTop: theme.spacing(2),
  }
});

function Navigator(props) {
  const {
    classes,
    ...other
  } = props;

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <div
            style={{
            height: "65px",
            boxAlign: "center"
          }}>
            <a href="https://targetepigenomics.org">
            <img
              // height="40px"
              width="130px"
              style={{
              // margin: "10px",
              padding: "10px"
            }}
              src="/TaRGET_logo.png"
              alt="logo"/>
              </a>
          </div>

        </ListItem>
        <ListItemText classes={{
          primary: classes.categoryHeader
        }}>
          Exposures
        </ListItemText>
        <ListItem>
          <Exposures/>
          {/* <ExposuresCustom/> */}
        </ListItem>
        <Divider className={classes.divider}/>

        <ListItemText classes={{
          primary: classes.categoryHeader
        }}>
          Tissues
        </ListItemText>
        <ListItem>
          <Tissues/>
        </ListItem>

        <Divider className={classes.divider}/>
        <ListItemText classes={{
          primary: classes.categoryHeader
        }}>
          Assays
        </ListItemText>
        <ListItem>
          <Assays/>
        </ListItem>

        

        <Divider className={classes.divider}/>
        <ListItemText classes={{
          primary: classes.categoryHeader
        }}>
          Labs
        </ListItemText>
        <ListItem>
          <Labs/>
        </ListItem>

        <Divider className={classes.divider}/>
        <ListItemText classes={{
          primary: classes.categoryHeader
        }}>
          Age
        </ListItemText>
        <ListItem>
          <Age/>
        </ListItem>

        <Divider className={classes.divider}/>
        <ListItemText classes={{
          primary: classes.categoryHeader
        }}>
          Sex
        </ListItemText>
        <ListItem>
          <Sex/>
        </ListItem>
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Navigator);