import React, {Fragment} from 'react';
import MultipleLineChart from './components/MultipleLineChart';
import * as d3 from 'd3';

const YieldDistributionNew = (props) => {
  if (Object.keys(props.data).length === 0) {
    return <p>Loading...</p>
  } else {
    const {data} = props;
    const YIELD_DISTRIBUTION = data['yield_distribution'];
    const EXPECTED_DISTINCTION = YIELD_DISTRIBUTION['expected_distinction'];
    const LOWER_CONFIDENCE = YIELD_DISTRIBUTION['lower_0.95_confidnece_interval'];
    const UPPER_CONFIDENCE = YIELD_DISTRIBUTION['upper_0.95_confidnece_interval'];

    const TOTAL_READS = YIELD_DISTRIBUTION['total_reads'];

    const one = formatDataY(TOTAL_READS, EXPECTED_DISTINCTION);
    const two = formatDataY(TOTAL_READS, UPPER_CONFIDENCE);
    const three = formatDataY(TOTAL_READS, LOWER_CONFIDENCE);
    const yDataLimits = calcYLimits([one, two, three]);
    const yData = [
      {
        name: 'Expected Distinction',
        data: formatDataY(TOTAL_READS, EXPECTED_DISTINCTION)
      }, {
        name: 'Upper 0.95 CI',
        data: formatDataY(TOTAL_READS, UPPER_CONFIDENCE)
      }, {
        name: 'Lower 0.95 CI',
        data: formatDataY(TOTAL_READS, LOWER_CONFIDENCE)
      }
    ];
    return (<MultipleLineChart
      xData={formatDataX(TOTAL_READS)}
      yData={yData}
      yExtent={yDataLimits}
      xAxisText="Total Reads"
      yAxisText=""
      chartLabel="Yield Distribution"/>);
  }
}

export default YieldDistributionNew;

function formatDataY(xData, yData) {
  const xTicks = d3
    .scaleLinear()
    .domain(d3.extent(xData))
    .ticks(20);
  const xTicksScale = d3
    .scaleLinear()
    .domain(xTicks)
    .range(yData);
  const yValues = xTicks.map(d => Math.round(Number.parseFloat(xTicksScale(d))));
  return yValues;
}

function formatDataX(xData) {
  const xTicks = d3
    .scaleLinear()
    .domain(d3.extent(xData))
    .ticks(20);
  return xTicks;
}

function calcYLimits(list) {
  const maxValues = [];
  list.forEach(dataArray => {
    const [min,
      max] = d3.extent(dataArray);
    maxValues.push(max);
  })

  return [
    0, Math.round(d3.max(maxValues))
  ];
}