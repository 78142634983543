import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useStoreState, useStoreActions } from 'easy-peasy';

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

export default function CustomizedBadges() {
  const selected = useStoreState(state => state.data.selected);
  const setActive = useStoreActions(actions => actions.tabs.setActive);


  if (selected.length === 0) {
    return null;
  }
  return (
    <IconButton aria-label="cart" onClick={() => setActive(4)}>
      <StyledBadge badgeContent={selected.length} color="secondary">
        <ShoppingCartIcon />
      </StyledBadge>
    </IconButton>
  );
}