import React, { Fragment } from 'react';

import Chart from 'react-apexcharts';

export default function LivePreviewExample(props) {
  const { mapping_distribution } = props.data;
  const data_to_draw = mapping_distribution['Percentage_of_non-redundant_uniquely_mapped_reads_in_autosome'];

  const options = {
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 0
    },
    xaxis: {
      categories: Object.keys(data_to_draw)
    },
    legend: {
      position: "bottom",
      horizontalAlign: "right"
    }
  };
  const series = [
    {
      data: Object.values(data_to_draw)
    }
  ];

  return (
    <Fragment>
      <Chart options={options} series={series} type="line" height={250}/>
    </Fragment>
  );
}
