import React from 'react';
import Chart from 'react-apexcharts';
import {Grid, Card} from '@material-ui/core';

export default function MultipleLineChart(props) {
  const {yData, xData, yExtent, yAxisText, xAxisText, chartLabel} = props;

  const options = {
    chart: {
      height: 450,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: [
      '#77B6EA', '#545454', '#40dfba'
    ],
    dataLabels: {
      enabled: true
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: chartLabel,
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: [
          '#f3f3f3', 'transparent'
        ], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: xData,
      title: {
        text: xAxisText
      }
    },
    yaxis: {
      title: {
        text: yAxisText
      },
      min: yExtent[0],
      max: yExtent[1]
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  };
  const data = yData;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={12}>
        <Card className="card-box mb-4 px-4 pt-4 text-center">
          <div className="card-header-alt">
            {/* <div className="font-size-lg mb-0 text-black">
              {chartLabel}
            </div> */}
            <p className="text-black-50 pb-4">Preseq result showing the estimated complexity of the ATAC-seq library</p>
          </div>
          <Chart options={options} series={data} type="line" height={350}/>
          <div className=" my-4"/>
        </Card>
      </Grid>
    </Grid>
  );
}
