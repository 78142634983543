import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControlLabel,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Button,
  List,
  ListItem,
  TextField,
  FormControl,
  ListItemText
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PersonIcon from '@material-ui/icons/Person';

import DialogContentText from '@material-ui/core/DialogContentText';
import { useStoreState } from 'easy-peasy';
import ProcessedFilesURLs from './ProcessedFilesURLs';
const fileDownload = require('js-file-download')
const UUID = uuid.v4();

export default function LivePreviewExample() {
  const added = useStoreState(state => state.data.added);
  const selected = useStoreState(state => state.data.selected);
  console.log(selected);
  // Example 4

  const [open3, setOpen3] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  let [processedFileURLsAll, setProcessedFileURLsAll] = useState([]);


  const handleClickOpen3 = scrollType => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open3) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open3]);

  const handleDownload = () => {
    fileDownload(processedFileURLsAll.join('\n'), `${UUID}-files.txt`)
  }

  const handleFileURLs = (input) => {
    setProcessedFileURLsAll(files => files.concat(input));
  }

  return (
    <Fragment>

      <Button
        className="m-2"
        variant="outlined"
        color="primary"
        onClick={handleClickOpen3('paper')}>
        Download Processed Data
      </Button>

      <Dialog
        open={open3}
        onClose={handleClose3}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">Download</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText>
            {`Processed files will be downloaded to ${UUID}-files.txt`}
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            <p className='m-4 font-hairline text-grey-dark'>Copy this file to any server.
            </p>
            <p>The following command using cURL can be used to download all the files in the list: 
            <strong className='m-4 bg-grey-lighter text-pink text-center'>{`xargs -n 1 curl -O -L &lt; ${UUID}-files.txt`}</strong>
            </p>
            {selected.map((d, i) => <ProcessedFilesURLs key={i} data={d} handleFileURLs={handleFileURLs} />)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownload} color="primary">
                Download
          </Button>
          <Button onClick={handleClose3} color="primary">
            Cancel
          </Button>
          
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
