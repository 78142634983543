import React from 'react';
import LineChartContainer from './components/LineChartContainer';
import SANITIZED_LABELS from '../sections/sanitized_labels.json';

const InsertionSizeDistribution = (props) => {
    if (Object.keys(props.data).length === 0) {
        return <p>Loading...</p>
    } else {
        const { data } = props;
        const INSERT_SIZE_DISTRIBUTION_DATA = data['insertion_size_distribution'];
        const INSERTION_SIZE = INSERT_SIZE_DISTRIBUTION_DATA['insertion_size'];
        const DENSITY = INSERT_SIZE_DISTRIBUTION_DATA['density'];
        const dataLabel = SANITIZED_LABELS['insertion_size'];
        return (
            <LineChartContainer xData={INSERTION_SIZE} yData={DENSITY} dataLabel={dataLabel} subtitle="Distribution of ATAC-seq insertion size" />
        );
    }
}

export default InsertionSizeDistribution;