import React from 'react'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ModalBasic from './components/ModalBasic';
import {generateMetadataContent, generateRawFileList} from './components/helper';
import { useStoreState } from 'easy-peasy';
const fileDownload = require('js-file-download')

const DownloadView = () => {
  const selected = useStoreState(state => state.data.selected);

  return (
    <React.Fragment>
      {/* <h1>DOWNLOAD</h1> */}
      {/* <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"> */}
        <ModalBasic/> {/* Data download */}
        {/* <Button>Metadata in Cart</Button> */}
        <Button variant="outlined"  onClick={() => fileDownload(generateMetadataContent(selected), `${Date.now()}-metadata.csv`)}>
          Download metadata
        </Button>
        <Button variant="outlined"  onClick={() => fileDownload(generateRawFileList(selected), `${Date.now()}-raw-data-files.csv`)}>
          Download Raw Data
        </Button>
      {/* </ButtonGroup> */}
      
    </React.Fragment>
  );
}

export default DownloadView;