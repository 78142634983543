import React, {Component} from 'react';
import axios from 'axios';
// import ReactJson from 'react-json-view';
import GraphsContainer from '../qc/GraphsContainer';
// import GraphsContainer from '../qc/GraphsContainerNew';
import {getScoreGeneric} from '../qc/qcutils';
import {Icon} from 'semantic-ui-react';

// import FileQCReport from './FileQCReport'; const QCREPORT_WEB_DIR =
// 'https://target.wustl.edu/files/ATAC-seq/b3bd19ff-8fee-4d6d-9056-d8d1f7e9c4b6
// / 5b1987badd88b278190a6c36/';
const QCREPORT_WEB_DIR = 'https://target.wustl.edu/files';
// const QCREPORT_WEB_DIR = 'https://s3.target.wustl.edu/';
// const API_PATH_BUILDER =
// 'https://5dum6c4ytb.execute-api.us-east-1.amazonaws.com/dev/file/qcreport/5ac
// 2 84bad3383f510b5dc5df';
const API_PATH_BUILDER = `${process.env.REACT_APP_REST_API}/files/qcreport/`;
const FILE_API = `${process.env.REACT_APP_REST_API}/files/`;
// const SUBMISSION_API = 'https://5dum6c4ytb.execute-api.us-east-1.amazonaws.com/dev/submission';

// https://target.wustl.edu/files/ATAC-seq/2e7e0c19-40d8-4a80-905b-25cb24442544/
// 5ad4c31fe19ec3f1bd2f0fd5/
// QC_ATAC_data_collection_5ad4c31fe19ec3f1bd2f0fd5.SE/
// plots_collection_5ad4c31fe19ec3f1bd2f0fd5.SE/

class FileQCReportContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qcJson: null,
            qcStatus: null,
            qcPath: undefined,
            assay: undefined
        }
    }
    async componentDidMount() {
        const fileUUID = this.props.match.params.id;
        // TODO: if no fileUUID, show error
        const URLtoPing = API_PATH_BUILDER + fileUUID;
        const URLtoPingFile = FILE_API + fileUUID;
        const path_api_res = await axios.get(URLtoPing).catch(() => {
            console.log('error in fetching PATH API');
        });
        let ASSAY = 'NA';
        if (path_api_res) {
            let resBody = path_api_res.data;
            if (resBody.hasOwnProperty('path')) {
                ASSAY = resBody.assay;
            }
        }
        
        const file_api_res = await axios.get(URLtoPingFile).catch(() => {
            console.log('error in fetching FILE API');
        });
        let SUBMISSION_ID = null;
        if (file_api_res) {
            SUBMISSION_ID = file_api_res.data.submission;

            // const QCDIR = `${QCREPORT_WEB_DIR}/${ASSAY}/${SUBMISSION}/${fileUUID}`;
            const getres = await axios.get(`${process.env.REACT_APP_REST_API}/submissions/${SUBMISSION_ID}`);
            const SUBMISSION_OBJ = getres.data;
            const QCDIR = getPipelineOutDirOnly(SUBMISSION_OBJ, fileUUID);
            const QCJSON = `${QCDIR}/${fileUUID}.json`;
            const json_res = await axios.get(QCJSON).catch(() => {
                console.log('error in fetching JSON');
            });
            if (json_res) {
                const qcJson = normalizeRootKey(json_res.data);
                const qcStatus = getScoreGeneric(ASSAY, qcJson)
                this.setState({qcJson, qcStatus, qcPath: QCDIR, qcJSONPath: QCJSON, assay: ASSAY });
            }
        }
        // const QCDIR = `${QCREPORT_WEB_DIR}${resBody.path}/${fileUUID}`;
    }
    render() {
        if (!this.state.qcJson) {
            return <h3>Looking up...</h3>
        }
        return (<GraphsContainer
            data={this.state.qcJson}
            qcStatus={this.state.qcStatus}
            id={this.props.match.params.id}
            type={this.state.assay}
            multiQC={`${this.state.qcPath}/multiqc_report.html`}
            fileUUID={this.state.qcJSONPath}/>);
    }
}

export default FileQCReportContainer;

function normalizeRootKey(json) {
    if (Object.keys(json).length === 0) {
        return null;
    }
    if (json.hasOwnProperty('Sample_QC_info')) {
        return json.Sample_QC_info;
    } else {
        return json;
    }
}

export function getPipelineOutDirOnly(SUBMISSION, file) {
    
    let ASSAY = undefined;
    const { _id, serverPath, assay } = SUBMISSION;
    // console.log(SUBMISSION);
    // console.log(_id, serverPath, assay);
    ASSAY = assay;
    if (assay === 'RRBS-Seq') {
        ASSAY = 'RRBS-seq';
    }
    
    let outDir = `${QCREPORT_WEB_DIR}/${ASSAY}/${_id}/${file}`;
    if (assay === 'RNA-seq' && serverPath) {
        outDir = `${QCREPORT_WEB_DIR}${serverPath}/${file}`;
    }

    return outDir;
}