import React, { Fragment } from 'react';

import Chart from 'react-apexcharts';

export default function LivePreviewExample(props) {

  const options = {
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 0
    },
    yaxis: {
      title: {
        text: props.yTitle || ""
      }
    },
    xaxis: {
      categories: props.data.x,
      title: {
        text: props.xTitle || ""
      }
    },
    legend: {
      position: "bottom",
      horizontalAlign: "right"
    }
  };
  const data = [
    {
      data: props.data.y
    }
  ];

  return (
    <Fragment>
      <Chart options={options} series={data} type="line" height={250}/>
    </Fragment>
  );
}
