import React from 'react';
import './App.css';
import './assets/base.scss';
// import DataSummary from './views/DataSummary';

// import NavBar from './components/NavBar';
import Base from './components/Base'
//GLOBAL STATE
import { createStore, StoreProvider, action } from 'easy-peasy';
// import model from './stores/model';
// const store = createStore(model);
const store = createStore({
  tabs: {
    names : ['Experiment Matrix', 'Experiment List', 'Download', 'Visualize', 'Cart'],
    active: 0,
    setActive : action((state, payload) => {
      state.active = payload;
    }),
    getActive : (state => state.names[state.active])
  },
  data: {
    added : [],
    add : action((state, payload) => {
      state.added = payload
    }),
    getAdded : (state => state.added),

    deleteFromCart : action((state, payload) => {
      state.selected = state.selected.filter(d => d._id !== payload._id)
    }),

    selected : [],
    setSelected: action((state, payload) => {
      state.selected = payload
    }),
    getSelected : (state => state.selected)
  }
});
function App() {
  return (
    <div>
      <StoreProvider store={store}>
      <Base/>
      </StoreProvider>
    </div>
  );
}

export default App;
