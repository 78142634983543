import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteIcon from '@material-ui/icons/Delete';
import { Card, Divider, Grid } from '@material-ui/core';
import { useStoreState, useStoreActions } from 'easy-peasy';
import DatahubView from '../DatahubView';
import DownloadView from '../DownloadView';
import CloseIcon from '@material-ui/icons/Close';

export default function LivePreviewExample() {
  const setActive = useStoreActions(actions => actions.tabs.setActive);
  const selected = useStoreState(state => state.data.selected);
  const deleteFromCart = useStoreActions(actions => actions.data.deleteFromCart)

  const handleDeleteFromCart = event => {
    deleteFromCart(event);
  }

  return (
    <Fragment>
      <Card className="card-box mb-4">
        <div className="card-header-alt p-4">
          <h6 className="font-weight-bold font-size-lg mb-1 text-black">
            Cart
          </h6>
          <div className="float-right cursor-pointer" onClick={() => setActive(0)}><CloseIcon/></div>
          {/* <p className="text-black-50 mb-0">
            Content added so far
          </p> */}

          <Grid container spacing={4} className='flex justify-between'>
            <Grid item>
              <DatahubView/>
            </Grid>
            <Grid item>
              <DownloadView/>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div className="card-body pt-2 px-4 pb-4">
          <table className="table-alternate text-nowrap mb-0 table table-borderless table-hover">
            <thead>
              <tr>
                <th>UUID</th>
                <th className="text-center">Tissue</th>
                <th className="text-center">Assay</th>
                <th className="text-left">Exposure</th>
                <th className="text-left">Lab</th>
                <th className="text-left">Sex</th>
                <th className="text-center" style={{ width: '15%' }}>
                  Submission
                </th>
                <th className="text-right">Score</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            { selected.map((d, index) => {  
              return <tr key={index}>
                <td>
                  <div>
                    <span className="text-black-50 d-block">
                      {d.uuid}
                    </span>
                  </div>
                </td>
                <td className="text-center">
            <span className="font-weight-bold text-success">{d.Tissue}</span>
                </td>
                <td className="text-center">
            <span className="font-weight-bold">{d.Assay}</span>
                </td>
                <td className="text-left">
                  {d.Exposure === 'Control' ? 
                    <span className="badge badge-warning">{d.Exposure}</span> :
                    <span className="badge badge-primary">{d.Exposure}</span> 
                  }
                </td>
                <td>{d.Lab}</td>
                <td>{d.Sex}</td>
                <td>
                  {d.submission}
                </td>
                <td className="text-right">
                  <div className="d-flex align-items-center justify-content-end">
                    {d.status === 'PASS' ?
                    <div className="font-weight-bold pr-2 text-success">
                      ✅
                    </div> :
                    <div className="font-weight-bold pr-2 text-danger">
                      ⚠️
                    </div>
                    }
                  </div>
                </td>
                <td className="cursor-pointer">
                  <DeleteIcon onClick={() => handleDeleteFromCart(d)}/>
                </td>
              </tr>
              })
              }
            </tbody>
          </table>
        </div>
      </Card>
    </Fragment>
  );
}
