import React, {Fragment} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Grid, Card, CardContent, Divider, Button} from '@material-ui/core';

import CountUp from 'react-countup';
import {Line} from 'react-chartjs-2';
export default function LivePreviewExample(props) {
  const { Assay, Tissue, Exposure, Dose, Age, Sex, Lab,
    assay_category,
    uuid } = props.data;
  return (
    <Fragment>
      <Card className="card-box mb-4">
        {/* <div className="card-header">
          <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
            Reports
          </h4>
        </div> */}
        <CardContent className="pb-0">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <div className="divider-v divider-v-md"/>
              <div className="d-flex align-items-center justify-content-between p-3">
                <div>
                  <b>Assay</b>
      <div className="text-black-50">{assay_category}</div>
                </div>
                <div className="font-weight-bold text-success font-size-xl">
                  {Assay}
                </div>
              </div>
              <Divider/>
              <div className="d-flex align-items-center justify-content-between p-3">
                <div>
                  <b>Tissue</b>
                  {/* <div className="text-black-50">Visitors last week</div> */}
                </div>
                <div className="font-weight-bold text-danger font-size-xl">
                  {Tissue}
                </div>
              </div>
              <Divider/>
              <div className="d-flex align-items-center justify-content-between p-3">
                <div>
                  <b>Exposure</b>
                  <div className="text-black-50">
                    {Dose}
                  </div>
                </div>
                <div className="font-weight-bold text-warning font-size-xl">
                  {Exposure}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="d-flex align-items-center justify-content-between p-3">
                <div>
                  <b>Age</b>
                  {/* <div className="text-black-50">month</div> */}
                </div>
                <div className="font-weight-bold text-warning font-size-xl">
                  {Age}
                </div>
              </div>
              <Divider/>
              <div className="d-flex align-items-center justify-content-between p-3">
                <div>
                  <b>Sex</b>
                  {/* <div className="text-black-50">Week's expenses</div> */}
                </div>
                <div className="font-weight-bold text-primary font-size-xl">
                  {Sex}
                </div>
              </div>
              <Divider/>
              <div className="d-flex align-items-center justify-content-between p-3">
                <div>
                  <b>Lab</b>
                  {/* <div className="text-black-50">
                    Total products ordered
                  </div> */}
                </div>
                <div className="font-weight-bold text-warning font-size-xl">
                  {Lab}
                </div>
              </div>
            </Grid>
          </Grid>
          
        </CardContent>
        {/* <div className="sparkline-full-wrapper">
              <Line data={data2Danger} options={data2DangerOptions} />
            </div> */}
                      <Divider />

            <div className="card-footer bg-light p-4 text-center">
            <a href={`/file/${uuid}`} target="_blank">
              <Button color="primary" variant="contained">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['far', 'eye']} />
                </span>
                <span className="btn-wrapper--label">View QC Report</span>
              </Button>
              </a>
            </div>
      </Card>
    </Fragment>
  );
}