import React from 'react';
import LineChartCustom from './components/LineChartCustom';
import {Grid, IconButton, Box, Badge, Card} from '@material-ui/core';

const MappingDistributionAutosome = (props) => {
    if (Object.keys(props.data).length === 0) {
        return <p>Loading...</p>
    } else {
        /**
         * percentage_of_non-redundant_uniquely_mapped_reads_in_chrX: 0.0288
            percentage_of_non-redundant_uniquely_mapped_reads_in_chrY: 0.0032
            percentage_of_uniquely_mapped_reads_in_chrM: 0.0498
         */
        const chrX = props.data.mapping_distribution['percentage_of_non-redundant_uniquely_mapped_reads_in_chrX'];
        const chrY = props.data.mapping_distribution['percentage_of_non-redundant_uniquely_mapped_reads_in_chrY'];
        const chrM = props.data.mapping_distribution['percentage_of_uniquely_mapped_reads_in_chrM'];

        return (
            <Grid container spacing={4}>
                <Grid item xs={12} lg={12}>
                    <Card className="card-box mb-4 px-4 pt-4 text-center">
                        <div className="card-header-alt">
                            <div className="font-size-lg mb-0 text-black">
                                Mapping Distribution
                            </div>
                            <p className="text-black-50">Reads distribution by chromosome</p> 
                        </div>
                        <LineChartCustom data={props.data}/>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <span className="opacity-6 pt-2">Non-redundantly Uniquely Mapped Reads</span>
                                <div className="d-flex align-items-center justify-content-center"></div>
                            </Grid>
                            <Grid item xs={12}>
                                <small className="opacity-6 pr-1">chrX :
                                </small>
                                <Badge color="secondary" className="text-success ml-2">
                                    {chrX}
                                    %
                                </Badge>
                                <small className="opacity-6 ml-2">
                                    chrY :
                                </small>
                                <Badge color="secondary" className="text-primary ml-2">
                                    {chrY}
                                    %
                                </Badge>
                            </Grid>
                            <Grid item xs={12}>
                                <span className="opacity-6">Uniquely Mapped Reads</span>
                                <div className="d-flex align-items-center justify-content-center"></div>
                            </Grid>
                            <Grid item xs={12}>
                                <small className="opacity-6 pr-1">chrM :
                                </small>
                                <Badge color="secondary" className="text-warning ml-2">
                                    {chrM}
                                    %
                                </Badge>
                            </Grid>
                        </Grid>
                        <div className=" my-4"/>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default MappingDistributionAutosome;