import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    // height: 800
  },
  left : {
    height: window.innerHeight * 0.6
  },
  right : {
    height: window.innerHeight * 0.3
  }
}));

const Overview = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
        <Card className={classes.left}>
        <p>Test</p>
        </Card>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
        <Card className={classes.right}>
        <p>Test</p>
        </Card>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
        <Card className={classes.right}>
        <p>Test</p>
        </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;